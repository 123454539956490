import PropTypes from "prop-types";
import cn from "classnames";
import { Media } from "solar/components/Media";
import NavbarSmall from "components/shared/adventures/NavbarSmall";
import NavbarBig from "components/shared/adventures/NavbarBig";
import styles from "components/shared/adventures/Navbar.module.scss";

export default function Navbar({
  className = null,
  bigPrimary = "links",
  bigSecondary = "logo",
  bigTertiary = "menu",
  bigNarrow = true,
  smallPrimary = "",
  smallSecondary = "logo",
  smallTertiary = "menu",
  borderBottom = false,
  parentName,
  parentPath,
}) {
  const smallLinks = [
    {
      href: "/surfer-development",
      text: "Surfer Development",
      newTab: false,
      inMenu: false,
    },
    {
      href: "/wsl",
      text: "WSL",
      newTab: false,
      inMenu: false,
    },
    {
      href: "/adventures",
      text: "Private",
      newTab: false,
      inMenu: false,
    },
    {
      href: "/events",
      text: "Group",
      newTab: false,
      inMenu: false,
    },
    {
      href: "/journal",
      text: "Stories",
      newTab: true,
      inMenu: false,
    },
    // rt: saved in case we need to use this for a collapsed menu in the future
    // {
    //   href: "",
    //   text: "More",
    //   newTab: false,
    //   inMenu: false,
    //   isMenuButton: true,
    // },
    // {
    //   href: "/stays",
    //   text: "Stays",
    //   newTab: false,
    //   inMenu: true,
    // },
    // {
    //   href: "/collections",
    //   text: "Collections",
    //   newTab: false,
    //   inMenu: true,
    // },
    {
      href: "",
      text: "Support",
      newTab: false,
      inMenu: false,
      isChat: true,
    },
    {
      href: "/login",
      text: "Sign in",
      newTab: false,
      inMenu: false,
      unauthenticated: true,
    },
    {
      href: "/traveler/inbox",
      text: "Messages",
      newTab: false,
      inMenu: false,
      authenticated: true,
    },
    {
      href: "/reservations/stays",
      text: "Reservations",
      newTab: false,
      inMenu: false,
      authenticated: true,
    },
    {
      href: "/wishlists",
      text: "Wishlists",
      newTab: false,
      inMenu: false,
      authenticated: true,
    },
    {
      href: "/account",
      text: "Account",
      newTab: false,
      inMenu: false,
      authenticated: true,
    },
    {
      href: "/logout",
      text: "Log out",
      newTab: false,
      inMenu: false,
      authenticated: true,
    },
  ];

  const bigLinks = [
    {
      href: "/surfer-development",
      text: "Surfer Development",
      newTab: false,
      inMenu: false,
    },
    {
      href: "/wsl",
      text: "WSL",
      newTab: false,
      inMenu: false,
    },
    {
      href: "/adventures",
      text: "Private",
      newTab: false,
      inMenu: false,
    },
    {
      href: "/events",
      text: "Group",
      newTab: false,
      inMenu: false,
    },
  ];

  return (
    <nav
      className={cn(styles.component, className, borderBottom && styles.border)}
    >
      <Media lessThan="lg">
        <NavbarSmall
          links={smallLinks}
          smallPrimary={smallPrimary}
          smallSecondary={smallSecondary}
          smallTertiary={smallTertiary}
          parentName={parentName}
          parentPath={parentPath}
        />
      </Media>
      <Media greaterThanOrEqual="lg">
        <NavbarBig
          links={bigLinks}
          bigPrimary={bigPrimary}
          bigSecondary={bigSecondary}
          bigTertiary={bigTertiary}
          bigNarrow={bigNarrow}
        />
      </Media>
    </nav>
  );
}

Navbar.propTypes = {
  className: PropTypes.node,
  bigPrimary: PropTypes.string,
  bigSecondary: PropTypes.string,
  bigTertiary: PropTypes.string,
  bigNarrow: PropTypes.bool,
  smallPrimary: PropTypes.string,
  smallSecondary: PropTypes.string,
  smallTertiary: PropTypes.string,
  borderBottom: PropTypes.bool,
  parentName: PropTypes.string,
  parentPath: PropTypes.string,
};
